@import url("https://rsms.me/inter/inter.css");
html {
  font-family: "Inter", sans-serif;
  padding: 0;
  margin: 0;
  background-color: #fcfbf8;
  color: #4a4a4a;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}

body,
p,
h1,
h2 {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: #0366d6;
}
